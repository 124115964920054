var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading_data_table,"headers":_vm.headers,"items":_vm.desserts,"sort-by":"corporate_name","pageCount":_vm.numberOfPagesPaginate,"page":_vm.currentPagePaginate,"server-items-length":_vm.totalStagesPaginate,"options":_vm.options,"footer-props":{
      itemsPerPageText: 'Itens por pagina',
      itemsPerPageOptions:[5,10,15],
      showFirstLastPage:true
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.total))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Formação de Preço")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-8",attrs:{"append-icon":"mdi-magnify","label":"Localizar","single-line":"","hide-details":""},on:{"click:append":function($event){return _vm.searchItems()},"change":function($event){return _vm.searchItems()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mb-2 ",attrs:{"color":"primary","rounded":"","dark":""},on:{"click":_vm.newFormingPrice}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Nova formação de preço")],1),_c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Tem certeza de que deseja excluir este item ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"primary",on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" Nenhum registro encontrado ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }